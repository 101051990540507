@import-normalize;

html {
  background-color: #262829;
}

body {
  color: white;
  font-weight: 300;
  height: 100%;
  margin: 0;
  overscroll-behavior: none;
  padding: 0;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.swiper {
  z-index: 0 !important;
}

* {
  box-sizing: border-box;
  --scrollbar-color-thumb: rgba(255, 255, 255, 0.60);
  --scrollbar-color-track: transparent;
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 5px;
}

:root {
  --animate-bouncing-min: 0.9;
  --animate-bouncing-max: 1.1;
  --animate-freshing-min: 1;
  --animate-freshing-max: 1.2;
  --animate-jumping-min: 0;
  --animate-jumping-max: -5px;
}

@keyframes bouncing {
  0% {
    transform: scale(var(--animate-bouncing-min));
  }
  50% {
    transform: scale(var(--animate-bouncing-max));
  }
  100% {
    transform: scale(var(--animate-bouncing-min));
  }
}

@keyframes freshing {
  0% {
    filter: brightness(var(--animate-freshing-min));
  }
  50% {
    filter: brightness(var(--animate-freshing-max));
  }
  100% {
    filter: brightness(var(--animate-freshing-min));
  }
}

@keyframes jumping {
  0% {
    transform: translateY(var(--animate-jumping-min));
  }
  50% {
    transform: translateY(var(--animate-jumping-max));
  }
  100% {
    transform: translateY(var(--animate-jumping-min));
  }
}

@supports (scrollbar-width: auto) {
  * {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }
}

@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
  }
  ::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
  }
  ::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
  }
}
